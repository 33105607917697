
























import { reactive, defineComponent, toRefs } from "@vue/composition-api"
import PrivacyPolicy from "@/components/PrivacyPolicy.vue"
import TermsOfService from "@/components/TermsOfService.vue"

export default defineComponent({
  components: { PrivacyPolicy, TermsOfService },

  name: "Dialog",

  setup() {
    const state = reactive({
      dialog: false,
      type: "",
      title: "",
    })

    const open = (type: string) => {
      state.type = type
      if (type === "TermsOfService") state.title = "서비스 이용약관"
      else if (type === "PrivacyPolicy") state.title = "개인정보처리방침"
      state.dialog = true
    }

    return {
      ...toRefs(state),
      open,
    }
  },
})
